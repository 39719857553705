import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/agers", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/agers/current", {params: removeEmptyParams(filter)}).then(res => {
          res.data.forEach(e => this.formatRes(e));
          return res.data;
        });
    },
    findAllCurrentForAdmin(filter) {
        return Api().get("/agers/current/admin", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/agers/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/ager/" + id).then(res => this.formatRes(res.data));
    },
    create(ager) {
        return Api().post("/ager", ager).then(res => this.formatRes(res.data));
    },
    update(ager) {
        return Api().put("/ager/" + ager.id, ager).then(res => this.formatRes(res.data));
    },
    delete(ager) {
        return Api().delete("/ager/" + ager.id).then(() => ager);
    },
    formatRes(e) {
        return e;
    }
}