<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    dateFormat: {
      type: String,
      required: false,
      default: "dd/mm/yy"
    },
    hourFormat: {
      type: String,
      required: false,
      default: "HH:mm"
    },
    showTime: {
      type: Boolean,
      required: false,
      default: true
    },
    timeOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    maxDate: {
      type: Date,
      required: false,
      default: null
    },
  },
  methods: {},
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">

    <AkLabel :required="isRequired">{{ this.label }}</AkLabel>
    <Calendar v-model="currentValue" :class="{'p-invalid':isInvalid && alreadySubmitted, 'disabled': isDisabled}"
              :date-format=this.dateFormat :hour-format=this.hourFormat
              :disabled="isDisabled" :showTime="showTime" :timeOnly="timeOnly"
              :max-date="maxDate"
              :placeholder="getPlaceholder" class="form-control"></Calendar>
    <small
        v-if="isValidatorRequired"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>

  </div>
</template>