import Api from "@/services/api";

export default {
  findAll() {
    return Api().get("/files").then(res => res.data);
  },
  findAllByForecastSheetId(forecastSheetId) {
    return Api().get("/files/forecast/" + forecastSheetId).then(res => res.data);
  },
  createForecastSheetFile(file, forecastSheetId) {
    return Api().post("/file/forecast/" + forecastSheetId, file, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => res.data);
  },
  update(file) {
    return Api().put("/file/" + file.id, file).then(res => this.formatResDTO(res.data));
  },
  deleteForecastSheetFile(file) {
    return Api().delete("/file/forecastSheet/" + file.id).then(() => file);
  },
  findAllByInterventionId(interventionId) {
    return Api().get("/files/intervention/" + interventionId).then(res => res.data);
  },
  deleteInterventionFile(file) {
    return Api().delete("/file/intervention/" + file.id).then(() => file);
  }
}