import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/ceis", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/ceis/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/ceis/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByUserId(userId) {
        return Api().get("/ceis/user/" + userId).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    getCeiAgents(ceiId){
        return Api().get("/ceis/" + ceiId + "/agents").then(res => {
            return res.data;
        });
    },
    createAgent(ceiId, agent){
        return Api().post("/ceis/" + ceiId + "/agents", agent).then(res => this.formatRes(res.data));
    },
    updateAgent(ceiId, agent){
        return Api().put("/ceis/" + ceiId + "/agents/" +agent.id , agent).then(res => this.formatRes(res.data));
    },
    deleteAgent(ceiId, agentId) {
        return Api().delete("/ceis/" + ceiId + "/agents/" + agentId );
    },
    findById(id) {
        return Api().get("/cei/" + id).then(res => this.formatRes(res.data));
    },
    create(cei) {
        return Api().post("/cei", cei).then(res => this.formatRes(res.data));
    },
    update(cei) {
        return Api().put("/cei/" + cei.id, cei).then(res => this.formatRes(res.data));
    },
    delete(cei) {
        return Api().delete("/cei/" + cei.id).then(() => cei);
    },
    formatRes(e) {
        return e;
    }
}