<script>
/* COMPOSANTS */
import AkCheckboxButton from "@components/input/AkCheckboxButton";

export default {
  components: {AkCheckboxButton},
  props: {
    modelValue: {
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: "col-md-6"
    },
    options: {
      type: Array,
      required: false,
      default: undefined
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      yesNo: [
      {label:this.$t('yes'), value:true}, 
      {label:this.$t('no'), value:false}, 
    ],
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {},
}
</script>

<template>
  <AkCheckboxButton
      v-model="currentValue"
      :label="label"
      :multiple="false"
      :options="options !== undefined ? options : yesNo"
      :class-name="className"
  />
</template>